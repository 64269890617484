@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


body {
	padding: 0;
	margin: 0;
	border-radius: 4px;
	font-family: 'Roboto', sans-serif;
	background-color: #060826;
	text-decoration: none;
}
a,
a:hover,
a:active,
a:focus {
	text-decoration: none;
}

a:active,
a:focus {
	color: #363636;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Oxanium',
		cursive;
	color: white;
}

img {
	max-width: 100%;
	height: auto;
}


.header {
	text-align: center;
	position: relative;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1;
	padding: 25px;
}

.boldest {
	font-weight: 900;
}

.theme-color {
	color: rgb(140, 0, 255);
}

/* menu */
.dfd-menu {
	list-style: none;
	padding: 10px 0;
	margin: 0;
}

.dfd-menu li {
	float: left;
}

.dfd-menu li a {
	font-size: 16px;
	text-transform: capitalize;
	color: #fff;
	padding: 20px 20px;
	text-decoration: none;
}

.menu-bar {
	display: none;
	font-size: 24px;
	color: #fff;
}

.header .fa-close {
	display: none;
}

.open .fa-bars {
	display: none;
}

.open .fa-close {
	display: block;
}


.default-btn {
	padding: 10px 30px;
	color: #fff;
	border-radius: 5px;
	display: inline-block;
	font-size: 16px;
	transition: .2s all linear;
	background-image: linear-gradient(to right, #4A00E0, #8E2DE2);
	font-weight: 600;
	text-transform: capitalize;
}

.default-btn:hover {
	background-image: linear-gradient(to left, #4A00E0, #8E2DE2);
	color: white;
}

.logo {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateY(-50%) translateX(-50%);
}

.monster-color {
	color: #FB6468;
}



.video-banner {
	height: 920px;
	position: relative;
	overflow: hidden;
	background-size: cover;
	background-position: center;
}

.bannar-conent {
	position: absolute;
	left: 0;
	top: 30%;
	transform: translateY(-50%);
	width: 100%;
	height: auto;
	text-align: center;
}

.bannar-conent-inner {
	max-width: 800px;
	width: 100%;
	margin: auto;
}

.video-banner video {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: -1;
}




.bannar-conent h1 {
	font-size: 42px;
	font-weight: 700;
	text-transform: uppercase;
	color: #f3297c;
	margin-top: 0;
	margin-bottom: 20px;
}

.bannar-conent p {
	font-size: 16px;
	color: #fff;
	max-width: 600px;
	margin: auto;
}

.banner-feature-list {
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 60px;
	text-align: center;
}

.banner-feature-list img {
	padding: 10px 15px;
	background-color: red;
	height: 85px;
	background-color: rgba(255, 255, 255, 0.03);
	border: 1px solid rgba(255, 255, 255, 0.06);
	border-radius: 3px;
	margin: 0 3px;
}




.landing-demo {
	padding: 100px 0;
}

.landing-demo.robotar {
	background: linear-gradient(to bottom, #000, #070540);

}

.landing-demo.herox {
	background: #171835;

}



.landing-demo.robotar h2,
p {
	color: white !important;
}

.landing-demo.robotar .default-btn {
	border: 2px solid white;
	background-image: linear-gradient(to right, #01ccff, #2de2ca);
	color: black;
}

.landing-demo.robotar .landingpage-image {
	background-color: #51eeffad;
}

.landing-demo-content {
	text-align: center;
	padding: 0 15px;

	@media screen (min-width:768px) {
		padding: 90px 60px;
	}
}


.landing-demo-content h2 {
	font-size: 42px;
	color: #fff;
	font-weight: 300;
	margin-bottom: 25px;
}

.landing-demo-content p {
	font-size: 18px;
	color: #585885;
}

.landingpage-image {
	max-height: 500px;
	overflow: hidden;
	background-size: cover;
	padding: 10px;
	background-color: #4bb4ff42;
	border-radius: 4px;

}


.landingpage-image img {
	transition: all 5s linear;
	border-radius: 4px;
}

.landingpage-image:hover img {
	transform: translateY(-75%);
}

.robotar-color {
	color: #8D59FF;
}

.herox-color {
	color: #fcb600;
}

.penqeens-color {
	color: #ff4cac;
}

.landing-demo.cutemonster .landingpage-image {
	background: #fb646899;
}

.landing-demo.herox .landingpage-image {
	background: #fcb60057;
}

.homepage-layout {
	/* background-image: url(../../assets/images/homepage-layout-bg.jpg); */
}

.homepage-layout .overlay {
	background-color: rgb(18, 18, 42);
}


.homepage-layout-content {
	max-width: 700px;
	width: 100%;
	margin: auto;
	text-align: center;
	padding: 150px 0;
}

.homepage-layout-content h2 {
	margin-top: 0;
	color: #fff;
	font-weight: 300;
	font-size: 42px;
}

.homepage-layout-content h2 span {
	font-weight: 700;
}

.homepage-layout-content p {
	color: #fff;
	margin-bottom: 25px;
	font-size: 18px;
}

.view-demos {
	font-size: 16px;
	color: #fff;
	border: 2px solid #fff;
	padding: 10px 30px;
	display: inline-block;
	text-transform: uppercase;
	border-radius: 30px;
	transition: 0.3s ease;
}

.pt-100 {
	padding-top: 100px !important;
}

.pb-100 {
	padding-bottom: 100px !important;
}

.view-demos:hover {
	background-color: #4bb6ff;
	border-color: #4bb6ff;
	color: #fff;
}
















/*   demo section  */
.demo-section {
	padding-left: 30px;
	padding-right: 30px;
	overflow: hidden;
	padding-top: 10px;

}

.white-color {
	color: white !important;
}

.demo-section.bg-grey {
	background: rgb(224, 223, 223);
	background: linear-gradient(180deg, rgba(224, 223, 223, 1) 90%, rgba(8, 2, 35, 0.6390756131554185) 100%);
}

.demo-section.bg-black {
	background: rgb(54, 54, 54);
	background: linear-gradient(0deg, #1f1a3e 90%, rgba(8, 2, 35, 0.6390756131554185) 100%);
}

.demo-item {
	width: 50%;
	float: left;
	padding-bottom: 60px;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}

.demo-item-inner {
	padding-left: 30px;
	padding-right: 30px;
}

.demo-item-inner a {
	display: block;
	width: 100%;
	overflow: hidden;
	border-radius: 8px;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}

.demo-item-inner img {
	width: 100%;
}

.demo-item:hover .demo-item-inner>a {
	box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.4);
}

.demo-item:hover {
	transform: translateY(-10px);
}

.feature-section h2 {
	font-size: 36px;
	font-weight: 300;
	color: #4bb5ff;
	text-align: center;
	margin-bottom: 50px;
}

.feature-img {
	margin-bottom: 25px;
	border-radius: 5px;
	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
}

.feature-img img {
	width: 100%;
	border-radius: 5px;
}

.feature-content {
	text-align: center;
}

.feature-content h5 {
	font-size: 20px;
	color: #666;
}

.feature-content p {
	font-size: 15px;
	color: #666;
	line-height: 24px;
	display: none;
}

.feature-section {
	background-color: #f8f7f7;
	padding-top: 100px;
}

.feature-wrapper.row {
	margin-left: -25px;
	margin-right: -25px;
}

.feature-wrapper .col-md-4 {
	padding-left: 25px;
	padding-right: 25px;
}

.feature-item {
	padding-bottom: 60px;
	text-align: center;
}

.footer-top {
	padding-top: 70px;
	padding-bottom: 100px;
	background-color: #171835;
}

.footer-top h2 {
	font-size: 36px;
	color: #fff;
	font-weight: 500;
}

.footer-top p {
	font-size: 18px;
	color: rgb(197, 197, 197);
}

.footer-top .purchase-now {
	font-size: 18px;
	color: #fff;
	padding: 10px 40px;
	background-color: #6d32f7;
	border-radius: 5px;
	margin-top: 20px;
	display: inline-block;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.footer-top .purchase-now:hover {
	background-color: #8f5fff;
}

.footer-bottom {
	background-color: #010004;
	padding: 30px 0;
}

.footer-bottom p {
	color: #aaa;
}

.footer-bottom p>a {
	color: #fff;
}


.new-offer-section {
	/* background: url(../../assets/images/offer-bg.jpg); */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.offer-countdown {}

.offer-countdown li {
	padding: 10px 20px;
	text-align: center;
}

.offer-countdown li p {
	margin-bottom: 0;
}

.offer-buy p {
	font-size: 14px;
}

.new-offer-section h2,
.new-offer-section h5 {
	font-weight: 500;
}

.new-offer-section h5 {
	text-transform: capitalize;
	margin-bottom: 10px;
}





/*** 

====================================================================
	Features Section
====================================================================

***/

.features-section {
	position: relative;
	padding: 90px 0px 60px;
}

.featured-block {
	position: relative;
	margin-bottom: 25px;
}

.featured-block .inner-box {
	position: relative;
	text-align: center;
	padding: 30px 15px;
	border-radius: 8px;
	background-color: #171835;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
}

.featured-block .inner-box .border-one {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	left: 0px;
	border-radius: 8px;
}

.featured-block .inner-box .border-one:before {
	position: absolute;
	content: '';
	right: 0px;
	top: 0px;
	width: 2px;
	height: 0px;
	background-color: #fff;
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
}

.featured-block .inner-box:hover .border-one::before {
	height: 100%;
}

.featured-block .inner-box .border-one:after {
	position: absolute;
	content: '';
	right: 0px;
	bottom: 0px;
	width: 0px;
	height: 2px;
	background-color: #fff;
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
}

.featured-block .inner-box:hover .border-one:after {
	width: 100%;
	transition-delay: 500ms;
}

.featured-block .inner-box .border-two {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	left: 0px;
	border-radius: 8px;
}

.featured-block .inner-box .border-two:before {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	width: 2px;
	height: 0px;
	background-color: #fff;
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
}

.featured-block .inner-box:hover .border-two:before {
	height: 100%;
	transition-delay: 1000ms;
}

.featured-block .inner-box .border-two:after {
	position: absolute;
	content: '';
	left: 0px;
	top: 0px;
	width: 0px;
	height: 2px;
	background-color: #fff;
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
}

.featured-block .inner-box:hover .border-two:after {
	width: 100%;
	transition-delay: 1500ms;
}

.featured-block .inner-box .icon-box {
	position: relative;
	width: 110px;
	height: 110px;
	text-align: center;
	border-radius: 50%;
	margin: 0 auto;
	background-color: #060826;
}

.featured-block:nth-child(2n + 1) .inner-box .icon-box {
	background-color: #060826;
}

.featured-block:nth-child(2n + 1) .inner-box:hover .border-one::before,
.featured-block:nth-child(2n + 1) .inner-box:hover .border-one::after,
.featured-block:nth-child(2n + 1) .inner-box:hover .border-two::before,
.featured-block:nth-child(2n + 1) .inner-box:hover .border-two::after {
	background-color: #fff;
}

.featured-block:nth-child(3n + 0) .inner-box .icon-box {
	background-color: #060826;
}

.featured-block:nth-child(3n + 0) .inner-box:hover .border-one::before,
.featured-block:nth-child(3n + 0) .inner-box:hover .border-one::after,
.featured-block:nth-child(3n + 0) .inner-box:hover .border-two::before,
.featured-block:nth-child(3n + 0) .inner-box:hover .border-two::after {
	background-color: #fff;
}

.featured-block .inner-box h5 {
	position: relative;
	color: #fff;
	font-weight: 700;
	margin-top: 22px;
}