@media screen and (max-width: 1400px) {
	.video-banner {
		height: 700px;
	}

	.bannar-conent h1 {
		font-size: 48px;
	}

	.bannar-conent h2 {
		font-size: 30px;
		margin-bottom: 15px;
	}

	.bannar-conent p {
		font-size: 15px;
	}
}

@media screen and (max-width: 1200px) {
	.video-banner {
		height: 500px;
	}

	.bannar-conent h1 {
		font-size: 42px;
	}

	.bannar-conent h2 {
		font-size: 24px;
		margin-bottom: 10px;
	}

	.bannar-conent p {
		font-size: 15px;
	}

	.banner-feature-list {
		bottom: 20px;
	}

	.dfd-menu li a {
		padding: 20px 5px;
	}
}


@media screen and (max-width: 991px) {
	.header .logo {
		left: 0;
		transform: translateY(-50%) translateX(20px);
	}

	.menu-bar {
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -10px;
		margin-top: -15px;
	}

	.dfd-menu {
		position: absolute;
		left: 0;
		width: 100%;
		top: 95px;
		background: #353535;
		transform: scaleY(0);
		transform-origin: 0 0 0;
		transition: all 0.3s ease;
	}

	.open .dfd-menu {
		transform: scaleY(1);
	}

	.dfd-menu li {
		float: none;
		width: 100%;
	}

	.dfd-menu li a {
		padding: 10px 20px;
		display: block;
	}

	.banner-feature-list {
		display: none;
	}

	.search-image {
		text-align: center;
	}
















}

@media screen and (max-width: 768px) {
	.demo-item {
		width: 100%;
	}

	.feature-item {
		padding-left: 30px;
		padding-right: 30px;
	}



	.video-banner {
		height: 300px;
	}

	.bannar-conent h1 {
		font-size: 30px;
		margin-top: 50px;
	}

	.bannar-conent h2 {
		font-size: 20px;
		margin-bottom: 10px;
	}

	.bannar-conent p {
		display: none;
	}

	.search-image img {
		width: 80%;
	}

}



@media screen and (max-width: 600px) {
	.header .purchase a {
		display: none;
	}

	.menu-bar {
		left: 100%;
		margin-left: -50px;
	}

	.event-search-content {
		text-align: center;
		padding: 0 15px;
	}
}